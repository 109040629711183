<template>
    <CustomCollapse open>
        <template #title>
            <h5 class="subtitle is-4 my-2 card-header-title p-0">
                {{ $t("admin.crm.modal.labels.postbackDetail.postbackConfiguration") }}
            </h5>
        </template>

        <template #icon="{ trigger }">
            <a class="card-header-icon my-2 py-2 pl-4 pr-0">
                <b-icon :icon="!trigger.open ? 'angle-down' : 'angle-up'"></b-icon>
            </a>
        </template>
        <template #content>
            <b-field :label="$t('admin.crm.modal.labels.postbackDetail.strategy')">
                <b-radio-button
                    :key="strategy.value"
                    v-for="strategy in strategyList.items"
                    v-model="postbackDetail.strategy"
                    :native-value="strategy.value"
                    type="is-info is-light">
                    <span>{{ strategy.label }}</span>
                </b-radio-button>
            </b-field>
            <b-field
                :label="$t('admin.crm.modal.labels.postbackDetail.accept')"
                :message="!validationOptions.accept && !accept.length ? $t('admin.crm.modal.message.errors.emptyField') : ''"
                type="is-danger">
                <FSelect
                    v-model="accept"
                    :create-option="name => ({ name, id: name })"
                    :get-data-vuex="getAccept"
                    :seed="JSON.stringify(accept)"
                    multiple
                    no-drop
                    taggable>
                </FSelect>
            </b-field>
    
            <b-field
                :label="$t('admin.crm.modal.labels.postbackDetail.spam')"
                :message="!validationOptions.spam && !spam.length ? $t('admin.crm.modal.message.errors.emptyField') : ''"
                type="is-danger">
                <FSelect
                    v-model="spam"
                    :create-option="name => ({ name, id: name })"
                    :get-data-vuex="getSpam"
                    :seed="JSON.stringify(spam)"
                    multiple
                    no-drop
                    taggable>
                </FSelect>
            </b-field>
    
            <b-field
                :label="$t('admin.crm.modal.labels.postbackDetail.cancel')"
                :message="!validationOptions.cancel && !cancel.length ? $t('admin.crm.modal.message.errors.emptyField') : ''"
                type="is-danger">
                <FSelect
                    v-model="cancel"
                    :create-option="name => ({ name, id: name })"
                    :get-data-vuex="getCancel"
                    :seed="JSON.stringify(cancel)"
                    multiple
                    no-drop
                    taggable>
                </FSelect>
            </b-field>
        </template>
    </CustomCollapse>
</template>

<script>
  import CustomCollapse from "@/components/Common/CustomCollapse";
  import { UPDATE_MODAL_OPTIONS, UPDATE_POSTBACK_STATUS_MAP } from "@core/store/mutation-constants";
  import { mapFields } from "@vasiliyrusin/vue-mapfields";
  import { mapState } from "vuex";
  import FSelect from "@/components/Common/FSelect";
  
  export default {
    name: "CRMCreatePostbackConfiguration",
    components: {
      FSelect,
      CustomCollapse
    },

    data () {
      return {
        validationOptions: {
          accept: true,
          spam: true,
          cancel: true
        }
      };
    },

    computed: {
      ...mapState("admin/crm/crmCreateModal",{
        strategyList: state => state.strategyList.data
      }),

      ...mapFields("admin/crm/crmCreateModal", {
        fields: ["postbackDetail"],
        base: "options",
        action: UPDATE_MODAL_OPTIONS
      }),

      ...mapFields("admin/crm/crmCreateModal", {
        fields: ["accept", "spam", "cancel"],
        base: "options.postbackDetail.statusMap",
        action: UPDATE_POSTBACK_STATUS_MAP
      })
    },

    methods: {
      isValid () {
        for (const key in this.validationOptions) {
          this.$set(this.validationOptions, key, !!this[key].length);
        }
        return Object.values(this.validationOptions).every(value => value === true);
      },

      getAccept () {
        const items = this.accept.map(name => {
          return {
            name,
            id: name
          };
        });
        return { items, count: this.accept.length };
      },

      getSpam () {
        const items = this.spam.map(name => {
          return {
            name,
            id: name
          };
        });
        return { items, count: this.spam.length };
      },

      getCancel () {
        const items = this.cancel.map(name => {
          return {
            name,
            id: name
          };
        });
        return { items, count: this.cancel.length };
      }
    }
  };
</script>

<style scoped>

</style>
