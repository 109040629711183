<template>
    <ModalBox
        :key="locale"
        :is-active="isCRMCreateModalActive"
        :is-loading="isLoading"
        :model="{ name, alias, requestUrl }"
        :on-cancel="() => closeModalConfirm()"
        @submit.stop.prevent="submit">
        <template #header>
            <h5
                v-if="isEdit"
                class="title is-5">
                {{ $t("admin.crm.modal.labels.editingCrm") }}
            </h5>
            
            <h5
                v-else
                class="title is-5">
                {{ $t("admin.crm.modal.labels.creatureCrm") }}
            </h5>
        </template>
        
        <div
            class="p-px">
            <FField
                :label="$t('admin.crm.modal.labels.name')"
                custom-class="text-sm">
                <RequiredField
                    v-model="name"
                    prop="name">
                </RequiredField>
            </FField>
            
            <FField
                v-show="!isEdit"
                :label="$t('admin.crm.modal.labels.alias')"
                custom-class="text-sm">
                <RequiredField
                    v-model="alias"
                    prop="alias"
                    
                    pattern="[a-z]+">
                </RequiredField>
            </FField>
        
            <b-checkbox
                v-model="isWithoutRequest"
                class="my-2">
                {{ $t("admin.crm.modal.labels.withoutRequest") }}
            </b-checkbox>
        
            <CRMCreateRequest
                ref="request"
                :request-url.sync="requestUrl">
            </CRMCreateRequest>
        
            <CRMCreateResponse ref="response">
            </CRMCreateResponse>
        
            <CRMCreateFlowRedirect ref="flowRedirect">
            </CRMCreateFlowRedirect>
    
            <CRMCustomMacros class="mt-4">
            </CRMCustomMacros>
    
            <CRMCreatePostbackConfiguration ref="postbackConfiguration">
            </CRMCreatePostbackConfiguration>
    
            <CRMCreateRecoveryDetail>
            </CRMCreateRecoveryDetail>
        </div>
        
        <template #footer>
            <b-button
                :disabled="isLoading"
                class="is-fullwidth is-info is-light"
                native-type="submit">
                {{ $t(`admin.finances.costs.modal.footer.confirm`) }}
            </b-button>
        </template>
    </ModalBox>
</template>

<script>
  import {
    CREATE_CRM,
    EDIT_CRM,
    GET_CRM,
    GET_CRM_BY_ALIAS,
    GET_FLOW_REDIRECT_MACROS,
    GET_MACROS,
    GET_METHODS,
    GET_REQUEST_TYPES,
    GET_RESPONSE_TYPES,
    GET_STRATEGY,
    PROXY_CRM,
    UPDATE_IS_WITHOUT_REQUEST,
    UPDATE_MODAL_ACTIVE
  } from "@core/store/action-constants";
  import { UPDATE_MODAL_OPTIONS } from "@core/store/mutation-constants";
  import { mapFields } from "@vasiliyrusin/vue-mapfields";
  import { mapActions, mapState } from "vuex";
  import ModalBox from "@/components/Common/ModalBox";
  import CRMCreateFlowRedirect from "@/components/Admin/CRM/CRMCreateModal/CRMCreateFlowRedirect";
  import CRMCreatePostbackConfiguration from "@/components/Admin/CRM/CRMCreateModal/CRMCreatePostbackConfiguration";
  import CRMCreateRecoveryDetail from "@/components/Admin/CRM/CRMCreateModal/CRMCreateRecoveryDetail";
  import CRMCreateRequest from "@/components/Admin/CRM/CRMCreateModal/CRMCreateRequest";
  import CRMCreateResponse from "@/components/Admin/CRM/CRMCreateModal/CRMCreateResponse";
  import CRMCustomMacros from "@/components/Admin/CRM/CRMCreateModal/CRMCustomMacros";
  import FField from "@/components/Common/FField";
  import RequiredField from "@/components/Common/RequiredField";
  
  export default {
    name: "CRMCreateModal",
  
    components: {
      RequiredField,
      FField,
      ModalBox,
      CRMCreateRequest,
      CRMCreateResponse,
      CRMCustomMacros,
      CRMCreatePostbackConfiguration,
      CRMCreateRecoveryDetail,
      CRMCreateFlowRedirect
    },

    created () {
      this.GET_METHODS();
      this.GET_REQUEST_TYPES();
      this.GET_RESPONSE_TYPES();
      this.GET_STRATEGY();
      this.GET_MACROS();
      this.GET_FLOW_REDIRECT_MACROS();
    },
      
    data () {
      return {
        requestUrl: null
      };
    },

    computed: {
      ...mapState("admin/crm/crmCreateModal", {
        isCRMCreateModalActive: state => state.isCRMCreateModalActive,
        isEdit: state => state.isEdit,
        _isWithoutRequest: state => state.isWithoutRequest
      }),
  
      ...mapFields("admin/crm/crmCreateModal", {
        fields: ["alias", "name"],
        base: "options",
        action: UPDATE_MODAL_OPTIONS
      }),
  
      isWithoutRequest: {
        get () {
          return this._isWithoutRequest;
        },
        set (value) {
          this.UPDATE_IS_WITHOUT_REQUEST(value);
        }
      },
  
      locale () {
        return this.$root.$i18n.locale;
      },
  
      isLoading () {
        return this.$wait(`admin/crm/crmCreateModal/${ CREATE_CRM }`)
          || this.$wait(`admin/crm/crmCreateModal/${ EDIT_CRM }`)
          || this.$wait(`admin/crm/${ GET_CRM_BY_ALIAS }`);
      }
    },

    methods: {
      ...mapActions("admin/crm/crmCreateModal", {
        PROXY_CRM,
        UPDATE_MODAL_ACTIVE,
        UPDATE_IS_WITHOUT_REQUEST,
        GET_METHODS: `methodsList/${ GET_METHODS }`,
        GET_REQUEST_TYPES: `requestTypesList/${ GET_REQUEST_TYPES }`,
        GET_RESPONSE_TYPES: `responseTypesList/${ GET_RESPONSE_TYPES }`,
        GET_STRATEGY: `strategyList/${ GET_STRATEGY }`,
        GET_MACROS: `macrosList/${ GET_MACROS }`,
        GET_FLOW_REDIRECT_MACROS: `flowRedirectMacrosList/${ GET_FLOW_REDIRECT_MACROS }`
      }),

      ...mapActions("admin/crm", {
        GET_CRM
      }),

      closeModalConfirm () {
        this.$buefy.dialog.confirm({
          title: this.$t("common.entity.modal.confirmationToCancel.title"),
          message: this.$t("common.entity.modal.confirmationToCancel.message"),
          confirmText: this.$t("common.entity.modal.confirmationToCancel.confirmText"),
          cancelText: this.$t("common.entity.modal.confirmationToCancel.cancelText"),
          type: "is-danger is-light",
          onConfirm: () => {
            this.UPDATE_MODAL_ACTIVE(false);
          }
        });
      },

      isValid () {
        return this.$refs.request.isValid()
          && this.$refs.flowRedirect.isValid()
          && this.$refs.postbackConfiguration.isValid();
      },

      async submit () {
        try {
          if (this.isValid()) {
            await this.PROXY_CRM();
            this.GET_CRM();
            this.UPDATE_MODAL_ACTIVE(false);
            this.$buefy.toast.open({
              message: this.isEdit
                ? this.$t("admin.crm.modal.message.success.crmEdited")
                : this.$t("admin.crm.modal.message.success.crmCreating"),
              type: "is-success"
            });
          } else {
            this.$buefy.toast.open({
              message: this.$t("admin.crm.modal.message.errors.notValidData"),
              type: "is-danger"
            });
          }
        } catch (e) {
          const errors = e.errors;
          if (errors && Array.isArray(errors)) {
            const messages = errors.map(e => e.message);

            this.$buefy.toast.open({
              message: this.$t(`admin.crm.modal.message.errors.${ messages[0] }`),
              type: "is-danger"
            });
          }
        }

      }
    }
  };
</script>

<style lang="scss" scoped>
    ::v-deep {
        .card-content {
            padding: 0;
        }
    }
</style>
