<template>
    <FField
        v-show="customMacros.length"
        :label="$t('admin.crm.modal.labels.integrationDetail.customMacros')">
        <div class="columns is-multiline">
            <div
                :key="macros"
                v-for="macros in customMacros"
                class="column is-6 py-2">
                <FField :label="macros">
                    <FInput
                        v-model="customFields[macros]">
                    </FInput>
                </FField>
            </div>
        </div>
    </FField>
</template>

<script>
  import { mapState } from "vuex";
  import FField from "@/components/Common/FField";
  import FInput from "@/components/Common/FInput";
  
  export default {
    name: "CRMCustomMacros",
    components: { FInput, FField },
    computed: {
      ...mapState("admin/crm/crmCreateModal", {
        customMacros: state => state.customMacros.customMacros,
        _customFields: state => state.customMacros.customFields
      }),
      
      customFields: {
        get () {
          return this._customFields;
        },
        set (value) {
          this.UPDATE_CUSTOM_MACROS({ customFields: value });
        }
      }
    }
  };
</script>

<style scoped>

</style>
