<template>
    <div v-if="data && data.items && data.items.length === 0">
        <slot name="empty">
            <div class="hero is-medium has-text-centered">
                <div class="hero-body">
                    <h2 class="is-size-4 is-bold has-text-weight-medium has-text-grey">
                        <slot name="placeholder">
                            {{ $t(`common.table.empty`) }}
                        </slot>
                    </h2>
                </div>
            </div>
        </slot>
    </div>
    <div v-else-if="data">
        <slot
            :aggregates="data.aggregates || {}"
            :count="data.count || 0"
            :items="data.items || []"></slot>
    </div>
    <div v-else>
        <slot name="loader">
            <div class="loader-wrapper">
                <b-loading
                    :active="true"
                    :is-full-page="false"></b-loading>
            </div>
        </slot>
    </div>
</template>

<script>
  export default {
    name: "TablePlaceholder",
    props: {
      data: {
        default: null,
        validator: prop => Object.prototype.toString.call(prop) === "[object Object]" || prop == null
      }
    }
  };
</script>

<style lang="scss" scoped>
    .loader-wrapper {
        min-height: 100px;
        position: relative;

        &::v-deep {
            .loading-background {
                background-color: transparent;
            }
        }
    }

    .hero {
        width: 100%;
    }
</style>
