<template>
    <CustomCollapse open>
        <template #title>
            <h5 class="subtitle is-4 my-2 card-header-title p-0">
                {{ $t("admin.crm.modal.labels.recoveryDetail.statusConfiguration") }}
            </h5>
        </template>

        <template #icon="{ trigger }">
            <a class="card-header-icon my-2 py-2 pl-4 pr-0">
                <b-icon :icon="!trigger.open ? 'angle-down' : 'angle-up'"></b-icon>
            </a>
        </template>

        <template #content>
            <b-field :label="$t('admin.crm.modal.labels.recoveryDetail.strategy')">
                <FSelect
                    v-model="recoveryDetail.strategy"
                    :get-data="getRecoveryStrategy"
                    field="label"
                    prop="value">
                </FSelect>
            </b-field>
        </template>
    </CustomCollapse>
</template>

<script>
  import CustomCollapse from "@/components/Common/CustomCollapse";
  import { GET_RECOVERY_STRATEGY, UPDATE_MODAL_OPTIONS } from "@core/store/action-constants";
  import { mapFields } from "@vasiliyrusin/vue-mapfields";
  import { mapActions, mapState } from "vuex";
  import FSelect from "@/components/Common/FSelect";
  
  export default {
    name: "CRMCreateRecoveryDetail",
    components: {
      FSelect,
      CustomCollapse
    },

    computed: {
      ...mapState("admin/crm/crmCreateModal",{
        recoveryStrategyList: state => state.recoveryStrategyList.data
      }),

      ...mapFields("admin/crm/crmCreateModal", {
        fields: ["recoveryDetail"],
        base: "options",
        action: UPDATE_MODAL_OPTIONS
      })
    },

    methods: {
      ...mapActions("admin/crm/crmCreateModal", {
        GET_RECOVERY_STRATEGY: `recoveryStrategyList/${ GET_RECOVERY_STRATEGY }`
      }),

      getRecoveryStrategy () {
        this.GET_RECOVERY_STRATEGY();
        return this.recoveryStrategyList;
      }
    }
  };
</script>

<style scoped>

</style>
