<template>
    <div class="columns is-multiline">
        <div class="column has-text-right">
            <Permissions :permissions="['CRM.MANAGE']">
                <b-button
                    type="is-success is-light"
                    @click="UPDATE_MODAL_ACTIVE(true)">
                    {{ $t("admin.crm.filters.labels.createCrm") }}
                </b-button>
            </Permissions>
        </div>

        <CRMCreateModal></CRMCreateModal>
    </div>
</template>

<script>
  import Permissions from "@/components/Common/Permissions";
  import { UPDATE_MODAL_ACTIVE } from "@core/store/action-constants";
  import { mapActions } from "vuex";
  import CRMCreateModal from "@/components/Admin/CRM/CRMCreateModal/CRMCreateModal";
  
  export default {
    name: "CRMFilters",
    components: {
      CRMCreateModal,
      Permissions
    },

    methods: {
      ...mapActions("admin/crm", {
        UPDATE_MODAL_ACTIVE: `crmCreateModal/${ UPDATE_MODAL_ACTIVE }`
      })
    }
  };
</script>

<style scoped>

</style>
