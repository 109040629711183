<template>
    <CustomCollapse open>
        <template #title>
            <h5 class="subtitle is-4 my-2 card-header-title p-0">
                {{ $t("admin.crm.modal.labels.integrationDetail.flow") }}
            </h5>
        </template>
        
        <template #icon="{ trigger }">
            <a class="card-header-icon my-2 py-2 pl-4 pr-0">
                <b-icon :icon="!trigger.open ? 'angle-down' : 'angle-up'"></b-icon>
            </a>
        </template>
        
        <template #content>
            <FField
                :label="$t('admin.crm.modal.labels.integrationDetail.flowRedirect')"
                custom-class="text-sm">
                <RequiredField
                    v-model="flowRedirect"
                    :rules="{ required: !validationOptions.flowRedirect, message: $t('admin.crm.modal.message.errors.notJsonRequestBody') }"
                    prop="flowRedirect">
                    <template #default="{ value, update }">
                        <el-input
                            ref="flowRedirect"
                            :value="value"
                            :rows="5"
                            type="textarea"
                            @input="update">
                        </el-input>
                    </template>
                </RequiredField>
            </FField>
    
            <b-taglist>
                <b-button
                    :key="macros"
                    v-for="macros in flowRedirectMacrosList"
                    class="tag"
                    size="is-small"
                    type="is-info"
                    @click="addMacro(`{${macros}}`, 'flowRedirect', 'textarea')">
                    {{ "{" + macros + "}" }}
                </b-button>
            </b-taglist>
        </template>
    </CustomCollapse>
</template>

<script>
  import CustomCollapse from "@/components/Common/CustomCollapse";
  import { CRM } from "@core/mixins";
  import { mapFields } from "@/plugins/mapFields";
  import { UPDATE_FLOW_REDIRECT } from "@core/store/action-constants";
  import { mapState } from "vuex";
  import FField from "@/components/Common/FField";
  import RequiredField from "@/components/Common/RequiredField";
  
  export default {
    name: "CRMCreateFlowRedirect",
    mixins: [CRM],
    components: {
      RequiredField,
      FField,
      CustomCollapse
    },
    
    data () {
      return {
        validationOptions: {
          flowRedirect: true
        }
      };
    },
    
    computed: {
      ...mapState("admin/crm/crmCreateModal", {
        flowRedirectMacrosList: state => state.flowRedirectMacrosList.data
      }),
      
      ...mapFields("admin/crm/crmCreateModal", {
        fields: ["flowRedirect"],
        base: "options",
        action: UPDATE_FLOW_REDIRECT
      })
    },
    
    methods: {
      isValid () {
        return Object.values(this.validationOptions).every(value => value === true);
      }
    },
    
    watch: {
      flowRedirect (value) {
        this.$set(this.validationOptions, "flowRedirect", this.isJsonString(value));
      }
    }
  };
</script>

<style lang="scss" scoped>
    ::v-deep {
        .el-textarea__inner {
            @apply p-2;
        }
    }
</style>
