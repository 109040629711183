<template>
    <div class="tile is-ancestor">
        <div class="tile is-vertical">
            <div
                :key="row"
                v-for="(tilesRow, row) in tilesMatrix"
                class="tile">
                <div
                    :key="column"
                    v-for="(tile, column) in tilesRow"
                    :class="tilesWidthClass"
                    class="tile is-child">
                    <slot
                        :column="column"
                        :item="tile"
                        :row="row"></slot>
                </div>
            </div>
            <slot name="footer"></slot>
        </div>
        <b-loading
            :active="isLoading"
            :is-full-page="false"></b-loading>
    </div>
</template>

<script>
  import _chunk from "lodash/chunk";

  export default {
    name: "TilesTable",
    props: {
      items: {
        type: Array,
        required: true
      },

      isLoading: {
        type: Boolean,
        default: false
      },

      columns: {
        type: Number,
        default: 1,
        // eslint-disable-next-line
        validator: el => 0 < el && el < 12
      }
    },
    computed: {
      tilesMatrix () {
        return _chunk(this.items, this.columns);
      },
      
      tilesWidthClass () {
        return `is-${ Math.max(1, Math.round(12 / this.columns)) }`;
      }
    }
  };
</script>

<style scoped>
 .tile {
     position: relative;
 }
</style>
