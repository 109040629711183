<template>
    <TablePlaceholder :data="CRM">
        <template slot-scope="{ items: _items }">
            <TilesTable
                :columns="4"
                :items="_items">
                <template slot-scope="{ item }">
                    <CRMTableItem
                        :item="item"
                        @delete="deleteCRM($event)"
                        @edit="selectCRM($event)">
                        <template #content>
                            &#8203;
                        </template>
                    </CRMTableItem>
                </template>
            </TilesTable>
            <b-loading
                :active="isLoading"
                :is-full-page="false"></b-loading>
        </template>
        <template slot="placeholder">
            {{ $t(`common.table.noData`) }}
        </template>
    </TablePlaceholder>
</template>

<script>
  import TablePlaceholder from "@/components/Common/Table/TablePlaceholder";
  import TilesTable from "@/components/Common/TilesTable";
  import { DELETE_CRM, GET_CRM, GET_CRM_BY_ALIAS, UPDATE_MODAL_ACTIVE } from "@core/store/action-constants";
  import { UPDATE_MODAL_IS_EDIT } from "@core/store/mutation-constants";
  import { mapActions, mapMutations, mapState } from "vuex";
  import CRMTableItem from "@/components/Admin/CRM/CRMTableItem";
  
  export default {
    name: "CRMTable",
    components: {
      CRMTableItem,
      TilesTable,
      TablePlaceholder
    },
    created () {
      this.GET_CRM();
    },
    
    computed: {
      ...mapState("admin/crm", {
        CRM: (state) => state.CRM
      }),
      
      isLoading () {
        return this.$wait(`admin/crm/${ GET_CRM }`);
      }
    },

    methods: {
      ...mapMutations("admin/crm/crmCreateModal", {
        UPDATE_MODAL_IS_EDIT
      }),

      ...mapActions("admin/crm", {
        GET_CRM,
        GET_CRM_BY_ALIAS,
        DELETE_CRM,
        UPDATE_MODAL_ACTIVE: `crmCreateModal/${ UPDATE_MODAL_ACTIVE }`
      }),

      selectCRM (alias) {
        this.UPDATE_MODAL_ACTIVE(true);
        this.UPDATE_MODAL_IS_EDIT(true);
        this.GET_CRM_BY_ALIAS(alias);
      },

      deleteCRM (alias) {
        this.$buefy.dialog.confirm({
          title: this.$t("admin.crm.table.removeCrmModal.title"),
          message: this.$t("admin.crm.table.removeCrmModal.confirm"),
          confirmText: this.$t("common.buttons.delete"),
          cancelText: this.$t("common.buttons.cancel"),
          type: "is-danger is-light",
          onConfirm: async () => {
            try {
              await this.DELETE_CRM(alias);
              this.$buefy.toast.open({
                message: this.$t("admin.crm.table.removeCrmModal.messages.success"),
                type: "is-success"
              });
              await this.GET_CRM();
            } catch {
              this.$buefy.toast.open({
                message: this.$t("admin.crm.table.removeCrmModal.messages.fail"),
                type: "is-danger"
              });
            }
          }
        });
      }
    }
  };
</script>

<style scoped>

</style>
