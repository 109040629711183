<template>
    <div class="wrapper">
        <CRMFilters></CRMFilters>
        <CRMTable></CRMTable>
    </div>
</template>

<script>
  import CRMFilters from "@/components/Admin/CRM/CRMFilters";
  import CRMTable from "@/components/Admin/CRM/CRMTable";

  export default {
    name: "CRM",
    components: { CRMTable, CRMFilters }
  };
</script>

<style scoped>

</style>